import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
    },
    modal: {
        
    },
    formContainer: {
        width: '100%',
        height: 'auto',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: 15,
        padding: '25px 30px',
        borderRadius: 5,
        position: 'relative',
        boxSizing: 'border-box',
        background: '#fff',
        '& label': {
            fontSize: 14,
        }
    },
    close: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: 35,
        height: 35,
        position: 'absolute',
        right: 15,
        top: 21,
        cursor: 'pointer',
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            top: 17,
        },
        '&:hover': {
            opacity: .75,
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
        paddingTop: 0,
        paddingBottom: 25,
        justifyContent: 'flex-start',
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    adresses: {
        width: '100%',
        '& > fieldset': {
            width: '100%',
        }
    },
    button: {
        width: '100%',
        maxWidth: 240,
        minWidth: 180,
        height: 45,
        background: '#326dff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        marginTop: 25,
        borderRadius: 3,
        fontSize: 14,
        color: '#fff',
        fontWeight: 500,
        cursor: 'pointer',
        border: 'none',
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            background: '#326dff',
            color: 'white', 
            opacity: .9, 
            boxShadow: 'none',
        }
    },
    formControlLabel: {
        marginTop: 12,
        // minWidth: '500px',
        flexWrap: 'nowrap',
        // overflowY: 'scroll',  
    },
    checkTerminals: {
        marginTop: 6,
        marginBottom: 13,
    }
}));