import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    discounts: {
        marginTop: 30,
        padding: 16,
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('600')]: {
            marginTop: 0,
            background: 'white',
        },
    },
    typography: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
    },
    title: {
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '19px',
    },
    order: {
        marginTop: 20,
        display: 'flex',
        height: 57,
        width: '100%',
        padding: '0 16px',
        marginBottom: 16,
        boxSizing: 'border-box',

        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        background: 'white',
        borderRadius: 5,
    },
    orderContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    orderChange: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editIcon: {
        width: 22,
        height: 22,
        color: '#476282',
        '&:hover': {
            color: '#326dff',
            cursor: 'pointer',
        }
    }
}));

export const useFormStyles = makeStyles(theme => ({
    dialogPaper: {
        [theme.breakpoints.down('600')]: {
            background: 'red',
        },
    },
    buttonWrapper: {
        margin: '0 auto',
        marginTop: 30,
        textAlign: 'center',
    },
    button: {
        textTransform: 'none',
    },
    discountRadios: {
        marginTop: 30,
        [theme.breakpoints.down('600')]: {
            marginTop: 0,
        },
    },
    discountInput: {
        display: 'flex',
        flexDirection: 'column',
        height: 57,
        width: '100%',
        padding: '0 16px 16px 16px',
        marginBottom: 16,
        boxSizing: 'border-box',

        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        background: 'white',
        borderRadius: 5,
        [theme.breakpoints.down('375')]: {
            padding: '0 8px 8px 8px',
        },
    },
    discountTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '57px',
        color: 'black'
    },
    discountInputWrap: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    discountRadio: {
        margin: 0,
        cursor: 'pointer',
    },
    discountHidden: {
        width: 328,
        [theme.breakpoints.down('375')]: {
            width: '100%',
        },
    },
    inputField: {
        marginTop: 8,
        [theme.breakpoints.down('600')]: {
            width: '90%',
        },
        '& input': {
            width: 328,
            height: 45,
            border: '1px solid #DADCE0',
            borderRadius: '5px',
            padding: '11px 50px 11px 16px',
            boxSizing: 'border-box',
            [theme.breakpoints.down('600')]: {
                width: '100%',
                padding: '11px 16px 11px 16px',
            },
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none',
        },
        '& .MuiInput-underline:after': {
            content: 'none',
        },
        '& .MuiInput-underline:before': {
            content: 'none',
        },
        '& .Mui-error input': {
            border: '1px solid red',
        }
    },
}));