import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    sortModeCategories: {
        marginBottom: 16,
    },
    sortModeProducts: {
        height: 'calc(100vh - 260px)',
        overflowY: 'scroll',
    },
    banners: {
        position: 'relative',
        minHeight: '400px',
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    bannersContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: 300,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            padding: '26px 5px',
            borderRadius: 5,
            border: '0.5px solid #DADCE0',
            background: 'white',
            boxSizing: 'border-box',
        }
    },
    bannerWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    banner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        marginBottom: 16,
        padding: 16,

        width: '100%',
        height: 88,
        background: 'white',
        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        borderRadius: 5,
        boxSizing: 'border-box',
    },
    dnd: { 
        width: 64,
        textAlign: 'center',
        position: 'relative',
        top: '-9px',
        cursor: 'pointer',
    },
    imageWrap: {
        width: 67,
        height: 56,
        borderRadius: 5,
    },
    image: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',

        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        // '&:hover': {
        //     opacity: .75,
        // }
    },
    titleWrap: {
        marginLeft: 16,
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        color: 'black',
    },
    noBanners: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 'calc(100% - 90px)',

        display: 'flex',
        justifyContent: 'center',

        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1,
        alignItems: 'center',
        color: '#476282',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            lineHeight: '54px',
        }
    },
    buttonsWrap: {
        position: 'absolute',
        bottom: 0,
        left: 'calc(50% - 190px)',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            width: '100%',
        }
    },
    buttons: {
        // position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    addBanner: {
        marginRight: 40,
        color: '#326DFF',
        backgroundColor: '#E9F1FC',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
        '&:hover': {
            color: 'white',
        }
    },
    controlWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    trash: {
        marginRight: 20,
    },
    trashIcon: {
        color: '#476282',
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.75'
        }
    },
    bannerContentWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputField: {
        display: 'flex',
        paddingLeft: 15,
        '& > input': {
            marginRight: 15,
            width: 328,
            height: 45,
            padding: '0 52px 0 16px',
            background: 'white',
            borderRadius: 5,
            border: '0.5px solid #476282',
            boxSizing: 'border-box',
        },
        '& > input:disabled': {
            border: '0.5px solid #476282',
            background: '#f5f5f5',
            opacity: .5,
        }
    },
    textField: {
        display: 'block',
        paddingRight: 15,
        '& > *': {
            padding: 0,
            // background: 'red',
        }
    },
    buttonSaveText: {
        width: 150,
        height: 45,
        background: '#326DFF',
        boxShadow: 'none',
        '&:hover': {
            background: '#6692ff',
            boxShadow: 'none',
        }
        
    },
    editIcon: {
        color: '#476282',
        cursor: 'pointer',
        '&:hover': {
            opacity: .75
        }
    },
    warningMessage: {
        marginBottom: 16,
        padding: '0 16px',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '20px',
        color: '#326dff',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            paddingTop: 16,
            fontSize: 14,
            textAlign: 'center',
        }
    },
    buttonWrap: {
        marginTop: 16,
        textAlign: 'center',
    },
    button: {
        width: 180,
        height: 45,
        // background: '#E9F1FC',
        background: 'rgb(50, 109, 255);',
        borderRadius: '5px',
        fontSize: 14,
        textTransform: 'none',
        fontWeight: 500,
        boxShadow: 'none',
        border: 'none',
        color: 'white',
        boxShadow: 'none',
        '&:hover': {
            background: 'rgb(84, 133, 255);',
            boxShadow: 'none',
        }
    },
}));