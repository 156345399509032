import React from 'react';

export const EyeIcon = ({ style }) => (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={style} d="M12 7.43204C15.79 7.43204 19.17 9.58834 20.82 12.9999C19.17 16.4115 15.8 18.5678 12 18.5678C8.2 18.5678 4.83 16.4115 3.18 12.9999C4.83 9.58834 8.21 7.43204 12 7.43204ZM12 5.40735C7 5.40735 2.73 8.55574 1 12.9999C2.73 17.4441 7 20.5925 12 20.5925C17 20.5925 21.27 17.4441 23 12.9999C21.27 8.55574 17 5.40735 12 5.40735ZM12 10.4691C13.38 10.4691 14.5 11.6029 14.5 12.9999C14.5 14.397 13.38 15.5308 12 15.5308C10.62 15.5308 9.5 14.397 9.5 12.9999C9.5 11.6029 10.62 10.4691 12 10.4691ZM12 8.44439C9.52 8.44439 7.5 10.4893 7.5 12.9999C7.5 15.5106 9.52 17.5555 12 17.5555C14.48 17.5555 16.5 15.5106 16.5 12.9999C16.5 10.4893 14.48 8.44439 12 8.44439Z" />
    </svg>
);

export const EyeOffIcon = ({ style }) => (
    <svg width="24" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={style} fill="black" d="M12 5.99995C15.79 5.99995 19.17 8.12995 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.01 6.21995C10.66 6.08995 11.32 5.99995 12 5.99995ZM10.93 7.13995L13 9.20995C13.57 9.45995 14.03 9.91995 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.00995 14.48 6.99995 12 6.99995C11.63 6.99995 11.28 7.04995 10.93 7.13995ZM2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.44995L2.01 3.86995ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37ZM6.11 7.96995L7.86 9.71995C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.88995 6.11 7.96995Z" />
    </svg>

);