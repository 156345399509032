import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const {open, alertOpen, handleClose, alertHandleClose, handleClickSubmit, title, description} = props;

  return (
    <div>
      {open && (
        <Dialog
          open={open}
          onClose={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          
          <DialogActions>
            <Button onClick={handleClickSubmit} color="primary" autoFocus>
              Да
            </Button>
            <Button onClick={handleClose} color="primary">
              Нет
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {alertOpen && (
        <Dialog
          open={alertOpen}
          onClose={alertHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          
          <DialogActions>
            <Button onClick={alertHandleClose} color="primary">
                  Ок
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}