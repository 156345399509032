import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240;

export default makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: 'none',
    },
    [theme.breakpoints.down("sm")]: {
      '& .MuiBackdrop-root': {
        background: 'transparent',
      }
    },
  },
  drawerPaper: {
    marginTop: 64,
    width: drawerWidth,
    background: '#F1F3F4',
    height: 'calc(100% - 64px)',
    '& hr': {
      display: 'none',
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      boxShadow: 'none',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    background: 'white',
    boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
  },
  headerLogo: {
    fontSize: 18,
    fontWeight: 600,
    color: '#326dff',
    paddingLeft: 10,
  },
  listWrapper: {
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        width: '100%',
        height: '100vh',
        background: 'white',
        borderRadius: 0,
    },
    '& .MuiListItem-button': {
      padding: '8px 8px 8px 24px',  
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    },
    '& div': {
      borderBottomRightRadius: '24px', 
      borderTopRightRadius: '24px',
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    },
    '& div:hover': {
      borderBottomRightRadius: '24px', 
      borderTopRightRadius: '24px',
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    }
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  iconMenu: {
    minWidth: 34,
    '& svg': {
      fill: '#476282;',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#476282;',
    display: 'flex',
    width: '225px',
    height: 'auto',
    borderBottomRightRadius: '24px', 
    borderTopRightRadius: '24px',
    boxSizing: 'border-box',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      borderRadius: 0,
    },
  },
  // nestedLink: {
  //   fontSize: 14,
  // },
  active: {
    color: '#326dff',
    background: 'rgba(50, 109, 255, 0.1)',
    '& .MuiListItemIcon-root': {
      color: '#326dff',
    },
  },
  nestedLink: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
    // '& .MuiListItemIcon-root svg': {
    //   width: 18,
    //   height: 18,
    //   fill: 'red',
    // },
    '& .MuiListItemIcon-root': {
      minWidth: 28,
    },
    '& .MuiList-padding': {
      padding: 0,
    },
    padding: 0,
  },
  collapse: {
    '& .MuiList-padding': {
      padding: 0,
    },
  },
  load: {
    width: '100%',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  listText: {
    color: '#476282;'
  },
  loader: {
    textAlign: 'center',
  },
  settingsListItem: {
    '& + div': {
      background: '#f5f5f5',
    }
  }
}));
