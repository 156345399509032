import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    tabs: {
        display: 'flex',
        marginBottom: '10px',
        flexWrap: 'wrap',
    },
    tab: {
        fontSize: 13,
        margin: '0 3px 5px 3px',
        padding: '7px 12px',
        background: '#326dff',
        borderRadius: '5px',
        cursor: 'pointer',
        color: 'white',
        '&:hover': {
            background: 'rgba(50, 109, 255, .75)',
        }
    },
    tabsContent: {
      marginTop: 20,
    },
    textarea: {
        fontFamily: 'Roboto, sans-serif',
        height: '55px !important',
        overflowY: 'auto !important',
    },
    cities: {
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: 75,
        overflow: 'hidden',
        '& > div> label': {
           marginRight: 0,
        },
        '& > div> label > .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'rgba(50, 109, 255, 1)',
        },
        '& > div > label > .MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: 'rgba(50, 109, 255, .04)',
        }
    },
    citiesWrapper: {
        position: 'relative',
        maxWidth: 110,
        marginRight: 24,
    },
    citiesMoreIcon: {
        position: 'absolute',
        top: 11,
        right: -11,
        width: 10,
        height: 20,
    },
    button: {
        width: '100%',
        maxWidth: '250px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        justifyContent: 'center',
        background: '#326dff',
        margin: '16px auto',
        borderRadius: '5px',
        fontSize: '16px',
        color: '#fff',
        cursor: 'pointer',
        border: 'none',
        '&:hover': {
            background: 'rgba(50, 109, 255, .75)',
        }
    },
    openCitiesCheckbox: {
        '& > label': {
          marginRight: 0,
        },
        '& > label > .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'rgba(50, 109, 255, 1)',
        },
        '& > label > .MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: 'rgba(50, 109, 255, .04)',
        }
      },
      citiesPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('650')]: {
          flexWrap: 'wrap',
        }
      },
      leftPanel: {
        display: 'flex',
        width: '30%',
        [theme.breakpoints.down('760')]: {
          // display: 'block',
          width: '100%',
        }
      },
      citiesName: {
        display: 'flex',
        maxWidth: 400,
        width: '100%',
        lineHeight: '62px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('650')]: {
          // width: '50%',
          maxHeight: 63,
          // overflow: 'hidden',
          // order: 1,
          fontSize: 13,
        }
      },
      citiesLabel: {
        position: 'relative',
        top: 5,
      },
      nameLine: {
        paddingRight: 18,
        fontWeight: 400,
      },
      citiesFields: {
        // height: 62,
        maxWidth: 500,
        width: '100%',
        marginRight: 30,
        [theme.breakpoints.down('650')]: {
          // order: 3,
          // margin: 0,
          '& > div > label': {
            fontSize: 13,
          }
        },
        '& > div': {
          position: 'relative',
          marginBottom: 0,
        },
        '& > div > label': {
          // position: 'absolute',
          top: 13,
          left: -60,
          zIndex: 0,
          [theme.breakpoints.down('650')]: {
            position: 'static',
          },
        },
        '& > div > input': {
          zIndex: 1,
        }
      },
      citiesField: {
        // background: 'red',
      },
      isOpenCities: {
        marginTop: 16,
      },
      citiesCheckbox: {
        // height: 62,
        [theme.breakpoints.down('650')]: {
          // width: '50%',
          // order: 2,
          '& > label > span': {
            fontSize: 13,
          }
        },
        '& > label': {
          marginRight: 0,
        },
        '& > label > .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'rgba(50, 109, 255, 1)',
        },
        '& > label > .MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: 'rgba(50, 109, 255, .04)',
        }
      },
      popoverWrapper: {
        // display: 'flex',
        position: 'relative',
      },
      popover: {
        
      },
      shop: {
        lineHeight: 1,
      },
      headerWrapper: {
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 500,
        [theme.breakpoints.down('650')]: {
          display: 'none'
        },
      },
      shop: {
        maxWidth: 400,
        width: '100%',
      },
      price: {
        width: '30%',
        [theme.breakpoints.down('760')]: {
          width: '100%',
        },
      }
  }));