import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import s from './delivery.module.css';

import CityForm from './cityForm';
import ZonesForms from './zonesForms';

let DeliveryForm = (props) => {
    const classes = useStyles();
    const { saveZones, editZone, deleteZone, saveCity, delivery, deliveryZone, initialValues } = props;

    return(
        <div className={s.formContainer}>
            <div className={classes.formWrap}>
                <div className={classes.controlLabel}>
                    <div className={classes.inputCheckbox}>
                        {delivery === 1 && (
                            <CityForm 
                                initialValues={initialValues}
                                saveCity={saveCity}
                            />
                        )}
                        {delivery === 2 && (
                            <ZonesForms 
                                classes={classes} 
                                initialValues={initialValues}
                                saveZones={saveZones}
                                editZone={editZone}
                                deleteZone={deleteZone}
                                deliveryZone={deliveryZone}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
const useStyles = makeStyles(theme => ({
    formWrap: {
        width: '100%',
    },
    inputCheckbox: {
        marginBottom: 16,
        '&:last-child': {
            marginBottom: 0,
        }
    },
    inputCheckboxWrap: {
 
    },
    controlLabel: {
        width: '100%',
        cursor: 'pointer',
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: '#326DFF'
        },
    },
    checkbox: {
        color: '#476282',
        padding: 0,
        marginRight: 8,
        '& svg.MuiSvgIcon-root': {
            width: 24,
            height: 24,
        }
    },
    textCheckbox: {
        color: '#476282',
        fontSize: 14,
        lineHeight: '24px',
        cursor: 'pointer',
    },
    fieldsWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },  
    buttonWrapper: {
        margin: '0 auto',
        marginTop: 30,
    },
    button: {
        '& disabled': {
            color: '#476282',
            background: '#F1F3F4',
            textTransform: 'capitalize',
        }
    },
    cityFields: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 8,
        padding: '8px 16px 24px 16px',
        border: '1px solid #DADCE0',
        borderRadius: 5,
    },
    cityCheckbox: {
        marginBottom: '28px',
    }
}));

export default DeliveryForm;
