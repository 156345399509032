import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import axios from "axios";
import { withFirebase } from "../../firebase";
import Reviews from "./Reviews";
import moment from "moment";

import { ClassOutlined } from "@material-ui/icons";

const prepareReviews = (reviews, marksTitle, lng = "ru") => {
	const mergedReviews = reviews.map((review) => {
		const newMarks = Object.keys(review.marks).map((key) => {
			return {
				title: marksTitle[key][lng],
				value: review.marks[key],
			};
		});

		const date = new Date(review.createdAt);
		const formattedDate = date.toISOString().split("T")[0];

		let impression = {};

		const formattedReviewText = review.comment.replace(/\n/g, "<br />");

		switch (review.impression) {
			case 1:
				impression = {
					grade: review.impression,
					title: "негативное 🤬",
				};
				break;
			case 2:
				impression = {
					grade: review.impression,
					title: "нейтральное 😐",
				};
				break;
			case 3:
				impression = {
					grade: review.impression,
					title: "позитивное 😍",
				};
				break;

			default:
				impression = {};
		}

		return {
			...review,
			comment: formattedReviewText,
			marks: newMarks,
			date: formattedDate,
			impression: impression,
		};
	});

	return mergedReviews;
};

const ReviewsContainer = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { brandId } = useParams();
	const shops = useSelector((state) => state.shops.shops);
	const [reviews, setReviews] = useState([]);
	const [newReviews, setNewReviews] = useState([]);

	const today = moment().format("YYYY-MM-DD");
	const thirtyDaysLater = moment().subtract(30, "days").format("YYYY-MM-DD");

	const [startDate, setStartDate] = useState(thirtyDaysLater);
	const [endDate, setEndDate] = useState(today);

	const mainSettings = useSelector((state) => state.shops.mainSettings);

	const activeBrand = shops
		?.flatMap((shop) => shop.brands)
		.find((brand) => brand.id === brandId && brand.isActive);

	const reviewSettings = activeBrand?.appSettings?.reviewSettings;

	const getReviews = () => {
		axios
			.get(`${mainSettings.ordersServerUrl}api/review`, {
				params: {
					app: mainSettings.bundleId,
					startDate: startDate,
					endDate: moment(endDate).add(1, "days").format("YYYY-MMM-DD"),
				},
			})
			.then((response) => {
				const result = prepareReviews(response.data, reviewSettings.marksTitle);

				setReviews(response.data);
				setNewReviews(result);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Failed to fetch review:", error);
			});
	};

	const updateReview = (review) => {
		axios
			.post(`${mainSettings.ordersServerUrl}api/review/${review.id}`, {
				...review,
			})
			.then((response) => {
				getReviews();
			})
			.catch((error) => {
				console.error("Failed to fetch review:", error);
			});
	};

	const handleHideReview = (id, hidden) => {
		const review = reviews.find((item) => item.id === id);

		const body = {
			...review,
			hidden: hidden,
		};

		updateReview(body);
	};

	useEffect(() => {
		if (mainSettings?.bundleId && reviewSettings) {
			getReviews();
		}
	}, [mainSettings, reviewSettings, startDate, endDate]);

	console.log(newReviews);

	return (
		<div>
			{!isLoading && (
				<Reviews
					reviews={newReviews}
					handleHideReview={handleHideReview}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
			)}
		</div>
	);
};

export default withFirebase(withRouter(ReviewsContainer));
