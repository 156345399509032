import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText'

// const useStyles = makeStyles(theme => ({
//   disabledMessage: {
//     marginTop: 6,
//     fontFamily: 'Roboto, sans-serif',
//     fontSize: 14,
//     color: 'red',
//   }
// }));

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export default function InputSelect({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) {
  // const classes = useStyles();
  return (
    <div>
      <FormControl error={touched && error}>
          <Select
            native
            {...input}
            {...custom}
            inputProps={{
              name: 'cityId',
              id: 'select-city'
            }}
          >
            {children}
          </Select>
          {renderFromHelper({ touched, error })}
          {/* {custom.disabled && (
            <div>
              <div className={classes.disabledMessage}>Обратитесь к разработчикам для обновления приложения</div>
            </div>
          )} */}
        </FormControl>
    </div>
  )
}