import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';

export const InfoIcon = ({ ariaOwns, ariaHaspopup, onMouseEnter, onMouseLeave, className }) => (
    <div aria-owns={ariaOwns} aria-haspopup={ariaHaspopup} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5 8C15.5 12.14 12.1475 15.5 8.0075 15.5C3.86 15.5 0.5 12.14 0.5 8C0.5 3.86 3.86 0.5 8.0075 0.5C12.1475 0.5 15.5 3.86 15.5 8ZM7.25 11C7.25 11.4125 7.5875 11.75 8 11.75C8.4125 11.75 8.75 11.4125 8.75 11V8C8.75 7.5875 8.4125 7.25 8 7.25C7.5875 7.25 7.25 7.5875 7.25 8L7.25 11ZM8 2C11.315 2 14 4.685 14 8C14 11.315 11.315 14 8 14C4.685 14 2 11.315 2 8C2 4.685 4.685 2 8 2ZM8 4.25C7.58579 4.25 7.25 4.58579 7.25 5C7.25 5.41421 7.58579 5.75 8 5.75C8.41421 5.75 8.75 5.41421 8.75 5C8.75 4.58579 8.41421 4.25 8 4.25Z" fill="#476282"/>
        </svg>
    </div>
);

const PopoverComponent = ({ title, hiddenTitle, className, ...props }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (props) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className={classes.lastTimeWorkLabelWrap}>
            <Typography className={classes.totalTimeFooter}>{title}</Typography>
            <InfoIcon
                ariaOwns={open ? 'mouse-over-popover' : undefined}
                ariaHaspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={className} />
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{hiddenTitle}</Typography>
            </Popover>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    totalTimeFooter: {
        position: 'relative',
        top: 0,
        color: '#000000',
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.003em',
    }, 
    footerLastTimeWorkLabelIcon: {
        marginLeft: 0,
        position: 'absolute',
        top: 'calc(50% - 8px)',
        right: -8,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: 3,
    },
    lastTimeWork: {
        marginTop: 10,
        marginBottom: 25,
        display: 'flex',
    },
    lastTimeWorkLabelWrap: {
        position: 'absolute',
        top: 0,
        right: 0,
        [theme.breakpoints.down('375')]: {
            top: 4,
        },
    },
    lastTimeWorkLabel: {
    },
    lastTimeWorkLabelIcon: {
        width: 20,
        height: 20,
        marginLeft: 10,
    },
  }));

export default PopoverComponent;