import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Loading from './Loading';

const useStyles = makeStyles(props => (
    {
    root: {
      background: '#326dff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 45,
      minWidth: 180,
      width: 'auto',
      fontSize: 14,
      marginTop: 10,
      '& span': {
        textTransform: 'capitalize',
      },
      '&:hover': {
        background: '#326dffcf',
      },
      '&:disabled': {
        background: '#F1F3F4', 
        color: '#476282',  
      },
    },
    label: {
      textTransform: 'capitalize',
    },
  }));

export default  function CustomizedButtons(props){
    const classes = useStyles();
    return(
        <Button className={`${classes.root} ${props.className}`} onClick={props.handleClick} disabled={props.disabled}>
           {props.load ? <Loading size={props.size} color={'#fff'}/> : props.text}
        </Button>
    )
};