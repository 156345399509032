const UPDATE_CONFIG = 'UPDATE-CONFIG';
const UPDATE_LOGIN = 'UPDATE-LOGIN';

let initialState = {
    user: {},
    login: null,
    firebaseConfig: null
}

const authReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_CONFIG: {
            return {...state, firebaseConfig: action.config}
        }
        case UPDATE_LOGIN: {
            return {...state, login: action.login}
        }
        default:
           return state;
    }
}

export const updateFirebaseConfig = (config) => ({type: UPDATE_CONFIG, config});
export const updateLogin = (login) => ({type: UPDATE_LOGIN, login});

export default authReducer;